/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

// You can delete this file if you're not using it

const pathname = window.location.pathname.split('/')[1]
const securityToken = 'FF4cjLHNkvz3NKrpbLHJ'
const requestToken = window.location.search.replace('?token=', '') || ''

if (
  (
    pathname === 'assurance' ||
    pathname === 'provisioning' ||
    pathname === 'CollaboratorsDetail'
  ) &&
  requestToken !== securityToken
) {
  window.location.href = '/'
}

if (pathname !== 'assurance' && pathname !== 'provisioning' && pathname !== 'CollaboratorsDetail') {
  const scrollClass = 'bg-primary'
  window.addEventListener('scroll', () => {
    const header = document.querySelector('#header')
    if (header) {
      if (!header.classList.contains(scrollClass)) header.classList.add(scrollClass)
      if (window.pageYOffset <= 0) header.classList.remove(scrollClass)
    }
  })
}
